.cart{
    font-size: 35px;    
    color: goldenrod;
    background-color: white;
    border: 2px solid goldenrod;
    border-radius: 50%;
    padding: 5% 25%;
}

.cartCount {
    font-size: small;
    font-weight: bold;
    position: fixed;
    bottom: 11%;
    right: 4%;
    background: goldenrod;
    padding: 0.2% 0.6%;
    border-radius: 50%;
    color: black;
    
}

.cartContainer {
    display: block;
    position: fixed;
    right: 5%;
    bottom: 5%;
    z-index: 99;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .cartContainer {
        right: 7%;
        bottom: 5%;
    }
    .cartCount {
        right: 5%;
        padding: 1% 2%;
    }
}