.ImageViewer{
    display: block;
    position: fixed;
    top: 1dvh;
    bottom: 1dvh;
    left: 1dvw;
    right: 1dvw;
    z-index: 99;
	background-color: rgba(255, 255, 255, 0.4);
  	-webkit-backdrop-filter: blur(5px);
  	backdrop-filter: blur(5px);
	border: 3px solid grey
}

.imageContainer {
	text-align: center;
    overflow-y: scroll;
	margin: auto;
	height: 91dvh;
}

.ImgpopupHeader {
	background-color: #6c757d;
	color: white;
	padding : 5px;
	vertical-align: middle;
	font-weight: bolder;
	font-size: larger;
} 