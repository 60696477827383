.AddProducts1 label1, .AddProducts1 button1 {
    margin: 1%;
    width: 20%;
}
#AddProducts{
    min-height: 90dvh;
}
.productLabel{
    font-size: 1.2i5rem;
    width : 10%;
}
.productController,.productSelect,#image {
    width: 30%;
}
#image1 {
    width:70%;
}
input1 {
    padding : 5px;
}
.rs-loader-backdrop{
    background-color: #ffffffa3 !important;
}