.Body{
    width: 100%;
    background-color: white;
    margin: 0px;
    padding: 0px;
}

@media only screen and (max-width: 600px) {
    .container__slider {
        width: 100%;
        height: 200px;
    }
    .slider__item img {
        height: 200px !important;
        width: 100%;
    }
}
