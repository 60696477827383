#Search, #Products {
    background-color: lightcyan;
}

/* Width of the scrollbar */
.Home::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track (background) */
.Home::-webkit-scrollbar-track {
    background: lightcyan; 
    border-radius: 10px;
  }
  
  /* Handle (scrolling thumb) */
.Home::-webkit-scrollbar-thumb {
    background: darkcyan; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
.Home::-webkit-scrollbar-thumb:hover {
    background: darkcyan; 
  }