.input-group > div > label , .input-group > div > input {
    font-size: 20ppx !important;
}

.input-group {
    margin-top : 5dvh;
}

.profileform {
    height: 94dvah;
    overflow-y: scraoll;
}