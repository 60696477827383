section#EnquiryForm {
    display: block;
    position: fixed;
    right: 3%;
    bottom: 12%;
    background-color: #ffc109;
    border-radius: 2%;
    max-width: 25dvw;
    max-height: 85dvh;
    box-shadow: 4px 1px 20px 2px;
}
.enq_container{
    background-color: white;
    border-radius: 3%;
    margin: 2%;
    width: 96%;
}
.enForm {
    padding:1%;
}
.form-group{
    text-align: left;
}
.EnqRow {
    margin-bottom: 0%;     
}
label{
    font-weight: bold;
    font-size: medium;
}
.enqFormContent {
    background-color: white;
    padding: 1%;
}
.sendEnq {
    margin-bottom: 5%;
}
/*
.listRow{
    max-height: 45dvh;
    overflow-y: scroll;
}*/
.enqForm {
    background: bisque;
    margin: 2%;
    padding: 2%;
    border-radius: 2%;
}
@media only screen and (max-width: 600px) {
    section#EnquiryForm {
        max-width: 95dvw;
        max-height: 80dvh;
    }
}