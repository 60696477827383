.Admin{
    color : black;
    background-image: lisnear-gradient(45deg, cyan,cyan,cyan,rgb(122, 237, 237), cyan, cyan, rgb(122, 237, 237),cyan,cyan,cyan);
    background-color: lightcyan;
}
/* Width of the scrollbar */
.Admin::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track (background) */
  .Admin::-webkit-scrollbar-track {
    background: lightcyan; 
    border-radius: 10px;
  }
  
  /* Handle (scrolling thumb) */
  .Admin::-webkit-scrollbar-thumb {
    background: darkcyan; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .Admin::-webkit-scrollbar-thumb:hover {
    background: darkcyan; 
  }