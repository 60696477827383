.ErrorContainer {
    background-image: linear-gradient(45deg, darkcyan, cyan, cyan, darkcyan);
    margin: 0px;
    padding: 0px;
    width: 100dvw;
    height: 100dvh;
    background-image: raadial-gradient(circle, #ed0f5b, #efefef21, #d400ffd4, #efefef21, #0080006b , #ed0f5b, #efefef21, #d400ffd4, #efefef21, #0080006b)
}

.ErrorBlock {
    padding : 25dvh;
}

.siteError, .Error,.pageError  {
    background: #a1ffff;
    padding: 20px;
    box-shadow: 0 18px 24px rgba(0, 0, 0, 0.1);
}