.Contact {
    width: 100%;
    background-color: darkcyan;
    color: white;
    padding: 15px;
    margin: 0px;
    font-size:16px;
}
.contactCard {
    margin: 0px;
}
.container {
    margin: 1%;
    padding-top:1%
}
.class div:first-child {
    text-align: center;
}
.contactCard div {
    padding-top: 5px;
}
.displayInlineBlock {
    display: inline-block;
    width: 33%;
    vertical-align: top;
}
.icon {
    display: inline-block;
    vertical-align: middle;
    width: 3%;
    margin-right: 2%;
    font-weight: bolder;
    font-size: 20px;
    text-align: right;
}
.fa-mobile-phone {
    font-size: 28px;
}

.fa-map-marker {
    font-size: 25px;
}
.iconVal {
    display: inline-block;
    vertical-align: top;
    width: 90%;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  @media only screen and (max-width: 600px) {
    .subpart {
        padding-bottom : 5%;
    }
}