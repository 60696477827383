.counterBox{
    border: 0px solid black;
    display: flex;
    align-items: center;

}
.counterDecrement , .counterIncrement , .counterText {
    margin:0px;
    padding: 0px;
}

.counterText p1 {
    margin:10px 0px;
}

.counterDecrement>button1, .counterIncrement>button1 {
    font-weight: bold;
    font-size: 18px;
    margin: 0px;
    width: 100%;
    height: 100%;
}
.Counter{
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .counterDecrement {
        width: 20%;
    }
    .counterIncrement {
        width: 20%;
    }
    .counterText {
        width: 60%;
    }
    .counterText>input {
        width: 85%;
    }
}