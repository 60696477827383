.Products {
    width: 100%;
    background-color: white;
    margin: 0px;
    padding: 5px;
}

.card {
    transition: transform 0.5s;
}
.card:hover1 {
    transform: scale(1.1);
}
.productBoxOLD {
    border: 2px solid #FFC107;
    border-radius: 5%;
    height: auto;
    width: 20%;
    margin: 2%;
    padding: 10px;
    text-align: center;
    font-size:larger;
    
}
.productBox {
    border: 2px solid darkcyan;
    border-radius: 5%;
    width: 18%;
    margin: 1%;
    padding: 1dvb;
    text-align: center;
    font-size:large;    
}

.productBox>img {
    border-radius: 5%;
    border: 2px solid darkcyan;
    object-fit: contain;
    transition: transform .2s;

}
.productBox>img:hover {
    object-fit: fi1ll;
    transform: scaale(1.1);
}

.productBoxH {
    border: 2px solid #FFC107;
    border-radius: 5%;
    width: 30dvw;
    margin: auto;
    padding: 1dvb;
    text-align: center;
    font-size:large;
    
}

.container{
    width: 100%;
}
@media only screen and (max-width: 600px) {
    .col-sm-12 {
        width: 90%;
        margin: 2%;
    }
}

.card-img-top {
    height: 250px;
}