.Header {
    width :100%;
    background-color: darkcyan;
    margin: 0px;
    padding: 5px;
    font-size: x-large;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
}

.Header a {
    text-decoration: none;
    color: white;
}
.headernav > ul > li {
    display: inline-block;
    text-decoration: none;
    margin: 0px 20px;
}
.navClass {
    text-align: right;
    font-size: medium;
}
.navMobileClass {
    color: white;
    display: none;
}
.Header > .container{
    margin: 0px;
    padding: 0px;
}
.barIcon {
    color:white;
    height: 5px;
}
@media only screen and (max-width: 600px) {
    .col-md-6 {
        width: 100%;
        margin: 2%;
    }
    nav {
        display: none;
    }
    .company {
        text-align: center;
    }
    .navMobileClass {
        display: block;
    }
}
