.loginForm {
    width: 30%;
    vertical-align: middle;
    margin: 10% 35%;
    border: 2px solid;
    border-radius: 2%;
    padding: 1%;
    font-size: 20px;
    font-weight: bold;
    background-color: whitesmoke;
}